

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="infotechno-hero infotechno-bg">
    <div class="container-fluid">
        <div class="row align-items-center">
            <!--baseline-->
            <div class="col-lg-6 col-md-6">
                <div class="infotechno-hero-text  wow move-up animated" style="visibility: visible;">

                    <h1 class="font-weight--reguler mb-15">BoopGest 4.0</h1>
                    <p></p>
                   
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="infotechno-hero-inner-images">
                    <div class="infotechno-inner-one">
                        <img class="img-fluid" src="assets/images/hero/home-infotechno-main-slider-slide-01-image-01.png" alt="">
                    </div>
                    <div class="infotechno-inner-two  wow move-up animated" style="visibility: visible;">
                        <img class="img-fluid" src="assets/images/hero/home-infotechno-main-slider-slide-01-image-02.png" alt="">
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>



<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                    <h3 class="heading">Mantenha a gestão do seu <span class="text-color-primary">negócio</span> <br /> na palma da mão!</h3>
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-5">
                    <div class="modern-number-01">
                        <div class="image">
                            <img class="img-fluid" src="assets/images/banners/boopgest.jpg" alt="">
                        </div>
                        
                      
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="cybersecurity-about-text">
                        <div class="text">O <span class="text-color-primary">BoopGest 4.0</span> é um software que lhe permite realizar a gestão do seu negócio em qualquer local onde quer que se encontre. Tem como principal característica a possibilidade de aceder a este através de qualquer dispositivo (Desktop, Tablet ou Smartphone).</div>
                        <div class="text">Este pode ser totalmente adaptável de forma a ir ao encontro das necessidades do seu negócio, porque na nossa prespetiva é o software que se tem de adaptar à empresa e não o contrário.</div>
                        <div class="text">Com o nosso software consegue acompanhar em tempo real todos os custos inerentes a um trabalho que esteja a ser realizado, permitindo por vezes tomar decisões rápidas que podem fazer a diferença.</div>
                    </div>
                </div>




            </div>
        </div>


        
    </div>
</div>
<div class="about-delivering-wrapper  bg-gray section-space--ptb_50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title-wrap text-center section-space--mb_20">
                    <h3 class="heading">Funcionalidades</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="delivering-optimal-wrap">
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title">Gestão Projetos / Obras</h5>
                           
                        </div>
                        <div class="row bg-grey">
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Informações Gerais</li>
                                    <li class="list-item">Gestão de Tarefas</li>
                                    <li class="list-item">Gestão de Custos (Incluindo Mão de Obra)</li>
                                
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/gestaoprojetos.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title">Análise de Resultados</h5>
                           
                        </div>
                        <div class="row bg-grey">
                            
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/analiseresultados.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Cálculo das Despesas (Despesa Material | Despesa Mão de Obra)</li>
                                    <li class="list-item">Lista de Despesas Projeto / Obra</li>
                                    <li class="list-item">Análise de Resultados por Cliente</li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Clientes</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Gestão de Contatos</li>
                                    <li class="list-item">Gestão de Projetos / Obras por Cliente</li>
                                    <li class="list-item">Análise de Resultados por Cliente</li>
                                  
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/gestaoclientes.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                          
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Registo de Horas / Ponto</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/registoponto.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Registo de Horas por Funcionário.</li>
                                    <li class="list-item">Cálculo de Horas Extras por Funcionário.</li>
                                    <li class="list-item">Registo de Ponto por Funcionário.</li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Fornecedores</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Informações Gerais.</li>
                                    <li class="list-item">Análise de Gastos por Fornecedor.</li>
                              
                                
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/fornecedor.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                           
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Equipamentos</h5>                          
                        </div>
                        <div class="row">
                          
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/equipamentos.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Informações Gerais do Equipamento</li>
                                    <li class="list-item">Lista de Equipamentos (Operacionais / Inoperacionais)</li>
                                    <li class="list-item">Registo de Revisões / Gastos por Equipamento</li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Despesas</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Registo de Despesas Gerais por Categoria</li>
                                    <li class="list-item">Análise de Despesas por Funcionário</li>
                                    <li class="list-item">Análise de Despesas por Categoria</li>
                                
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/despesas.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Férias</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/ferias.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Registo de Férias</li>
                                    <li class="list-item">Possibilidade de Pedido de Alteração de Férias</li>
                                    <li class="list-item">Mapa de Férias</li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Frota</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Informações Gerais por Viatura</li>
                                    <li class="list-item">Alertas para Seguro, Inspeção e Imposto.</li>
                                    <li class="list-item">Registo de Manutenções por Viatura</li>
                                
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/frota.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h5 class="title"> Gestão de Colaboradores</h5>                          
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="modern-number-01">
                                    <div class="image">
                                        <img class="img-fluid" src="assets/images/boopgest/colaborador.png" alt="">
                                    </div>
                                    
                                  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <ul class="check-list">
                                    <li class="list-item">Informações Gerais de Colaborador</li>
                                    <li class="list-item">Gestão de Férias</li>
                                    <li class="list-item">Gestão de Ausências</li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>
            
                </div>
            </div>
         
        </div>
    </div>
</div>


</div>
<app-footer></app-footer>
</div>