<div id="main-wrapper">

   

<div class="site-wrapper-reveal section-space--pt__120">
    <!--============ Service Hero Start ============-->
    <div class="service-hero-wrapper service-hero-space service-hero-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 ml-auto mr-auto">
                    <div class="service-hero-wrap wow move-up">
                        <div class="service-hero-text text-center">
                          
                            <h1 class="font-weight--reguler text-white mb-30">Transformação Digital</h1>
                            <h4 class="text-white">Somos o parceiro ideal para ajudar a sua empresa a tornar-se mais digital.</h4>

                         
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--============ Service Hero End ============-->
    <!--===========  feature-images-wrapper  Start =============-->
 




    <!--===========  Our Company History Start =============-->

    <div class="our-company-history section-space--ptb_100">
        <div class="container-fluid">
           

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="faq-custom-col">
                        <div class="section-title-wrap">
                            <h6 class="section-sub-title mb-20">Sobre Nós</h6>
                            <h3 class="heading">Cada <span class="text-color-primary">cliente</span> tem as suas necessidades tecnológicas.</h3>

                            <p class="text mt-30">A BoopIT - Engenharia de Software tem uma equipa especializada para colaborar com os nossos clientes, focando-se para apresentar soluções tecnológicas adequadas às necessidades de cada um.</p>

                            <div class="inner-button-box section-space--mt_60">
                                <a href="/contatos" class="ht-btn ht-btn-md">Vamos conversar</a>
                            </div>
                        </div>
                    </div>
                </div>
               <div class="col-lg-6">
                                    <div class="slide-image">
                                        <div class="image-wrap">
                                            <div class="image">
                                                <img class="img-fluid" src="assets/images/home/development.jpg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
            </div>
        </div>
    </div>

    <!--===========  Our Company History Start  End =============-->






    <!--===========  feature-icon-wrapper  Start =============-->
    <div class="feature-icon-wrapper bg-gray section-space--ptb_100">
        <div class="container">

            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title-wrap text-center section-space--mb_40">
                        <h3 class="heading"><span class="text-color-primary">Os nossos serviços</span></h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="feature-list__two">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 wow move-up">
                                <div class="ht-box-icon style-02 single-svg-icon-box">
                                    <div class="icon-box-wrap">
                                        <div class="icon">
                                             <div class="svg-icon" id="svg-1" data-svg-icon="assets/images/svg/050-analytics.svg"></div>
                                        </div>
                                        <div class="content">
                                            <h5 class="heading">Software de Gestão</h5>
                                            <div class="text">Com a nossa solução <b>BoopGest</b> consegue aceder ao seu negócio em tempo real e em qualquer lugar.                                                    </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow move-up">
                                <div class="ht-box-icon style-02 single-svg-icon-box">
                                    <div class="icon-box-wrap">
                                        <div class="icon">
                                            <div class="svg-icon" id="svg-2" data-svg-icon="assets/images/svg/001-coding.svg"></div>
                                        </div>
                                        <div class="content">
                                            <h5 class="heading">Desenvolvimento de Software e Aplicações</h5>
                                            <div class="text">Trabalhamos, em conjunto consigo, para desenhar e desenvolver as ferramentas necessárias para otimizar o seu negócio.
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow move-up">
                                <div class="ht-box-icon style-02 single-svg-icon-box">
                                    <!-- ht-box-icon Start -->
                                    <div class="icon-box-wrap">
                                        <div class="icon">
                                            <div class="svg-icon" id="svg-3" data-svg-icon="assets/images/svg/023-cms.svg"></div>
                                        </div>
                                        <div class="content">
                                            <h5 class="heading">Intranet</h5>
                                            <div class="text">Acreditamos que a otimização de processos internos da sua empresa será o caminho para o sucesso.
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow move-up">
                                <div class="ht-box-icon style-02 single-svg-icon-box">
                                    <!-- ht-box-icon Start -->
                                    <div class="icon-box-wrap">
                                        <div class="icon">
                                                      <div class="svg-icon" id="svg-4" data-svg-icon="assets/images/svg/004-design.svg"></div>
                                        </div>
                                        <div class="content">
                                            <h5 class="heading">Desenvolvimento Websites</h5>
                                            <div class="text">Uma forte presença online é fundamental para a visibilidade da sua empresa.
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow move-up">
                                <div class="ht-box-icon style-02 single-svg-icon-box">
                                    <!-- ht-box-icon Start -->
                                    <div class="icon-box-wrap">
                                        <div class="icon">
                                            <div class="svg-icon" id="svg-5" data-svg-icon="assets/images/svg/016-internet.svg"></div>
                                        </div>
                                        <div class="content">
                                            <h5 class="heading">Alojamento e Domínios</h5>
                                            <div class="text">
                                                Somos o seu parceiro para fornecer a infraestrutura necessária para colocar a sua empresa online.
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow move-up">
                                <div class="ht-box-icon style-02 single-svg-icon-box">
                                    <!-- ht-box-icon Start -->
                                    <div class="icon-box-wrap">
                                        <div class="icon">
                                            <div class="svg-icon" id="svg-6" data-svg-icon="assets/images/svg/017-inspiration.svg"></div>
                                        </div>
                                        <div class="content">
                                            <h5 class="heading">Consultoria</h5>
                                            <div class="text">
                                                Realizamos serviço de Consultoria para, em conjunto consigo, tornar a sua empresa eficiente e mais digital.
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="feature-list-button-box mt-30 text-center">
                        <a href="/contatos" class="ht-btn ht-btn-md ht-btn--outline">Vamos conversar? </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--===========  feature-icon-wrapper  End =============-->



    <!--====================  gradation process area ====================-->
    <div class="gradation-process-area section-space--ptb_100">
        <div class="container">

            <div class="row">
                <div class="col-lg-12">
                    <div class="gradation-title-wrapper section-space--mb_60">
                        <div class="gradation-title-wrap ">
                            <h6 class="section-sub-title text-black mb-20"></h6>
                            <h4 class="heading">Como podemos <span class="text-color-primary">ajudar <br> a transformar o seu negócio ?</span></h4>
                        </div>


                        <div class="gradation-sub-heading">
                            <h3 class="heading"><mark>04</mark> Passos</h3>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="ht-gradation style-01">
                        <!-- Single item gradation Start -->
                        <div class="item item-1 animate  wow fadeInRight" data-wow-delay="0.1s">
                            <div class="line"></div>
                            <div class="circle-wrap">
                                <div class="mask">
                                    <div class="wave-pulse wave-pulse-1"></div>
                                    <div class="wave-pulse wave-pulse-2"></div>
                                    <div class="wave-pulse wave-pulse-3"></div>
                                </div>

                                <h6 class="circle">1</h6>
                            </div>

                            <div class="content-wrap">

                                <h6 class="heading">01. Discussão</h6>

                                <div class="text">Reunimos consigo para, em conjunto, compreender as verdadeiras necessidades da sua empresa e onde poderemos atuar.</div>

                             
                            </div>
                        </div>
                        <!-- Single item gradation End -->

                        <!-- Single item gradation Start -->
                        <div class="item item-1 animate  wow fadeInRight" data-wow-delay="0.15s">
                            <div class="line"></div>
                            <div class="circle-wrap">
                                <div class="mask">
                                    <div class="wave-pulse wave-pulse-1"></div>
                                    <div class="wave-pulse wave-pulse-2"></div>
                                    <div class="wave-pulse wave-pulse-3"></div>
                                </div>

                                <h6 class="circle">2</h6>
                            </div>

                            <div class="content-wrap">

                                <h6 class="heading">02. Análise de Requisitos</h6>

                                <div class="text">Realizamos uma análise de requisitos de acordo com as necessidades identificadas na etapa anterior.</div>

                             
                            </div>
                        </div>
                        <!-- Single item gradation End -->

                        <!-- Single item gradation Start -->
                        <div class="item item-1 animate  wow fadeInRight" data-wow-delay="0.20s">
                            <div class="line"></div>
                            <div class="circle-wrap">
                                <div class="mask">
                                    <div class="wave-pulse wave-pulse-1"></div>
                                    <div class="wave-pulse wave-pulse-2"></div>
                                    <div class="wave-pulse wave-pulse-3"></div>
                                </div>

                                <h6 class="circle">3</h6>
                            </div>

                            <div class="content-wrap">

                                <h6 class="heading">03. Desenvolvimento e Testes</h6>

                                <div class="text">O cliente é parte ativa durante o processo de desenvolvimento e testes para conseguirmos garantir que o produto final corresponde com as expetativas.</div>

                               
                            </div>
                        </div>
                        <!-- Single item gradation End -->

                        <!-- Single item gradation Start -->
                        <div class="item item-1 animate wow fadeInRight" data-wow-delay="0.25s">
                            <div class="line"></div>
                            <div class="circle-wrap">
                                <div class="mask">
                                    <div class="wave-pulse wave-pulse-1"></div>
                                    <div class="wave-pulse wave-pulse-2"></div>
                                    <div class="wave-pulse wave-pulse-3"></div>
                                </div>

                                <h6 class="circle">4</h6>
                            </div>

                            <div class="content-wrap">

                                <h6 class="heading">04. Instalação e Formação</h6>

                                <div class="text">Garantimos todo um suporte inicial para a implementação da ferramenta na sua empresa.</div>

                            </div>
                        </div>
                        <!-- Single item gradation End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====================  End of gradation process area  ====================-->

 <!--========== Call to Action Area Start ============-->
    <div class="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-8 col-lg-7">
                    <div class="cta-content md-text-center">
                        <h3 class="heading text-white">Ajudamos a sua empresa a encontrar o caminho para o  <span class="text-color-secondary"> sucesso!</span></h3>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                    <div class="cta-button-group--one text-center">
                        <a href="/contatos" class="btn btn--white btn-one"><span class="btn-icon mr-3"><i class="far fa-comment-alt-dots"></i></span> Vamos conversar ? </a>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>

<div class="testimonial-slider-area section-space--ptb_120 bg-gray-3">
        <div class="container-fluid container-fluid--cp-80">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title-wrap text-center section-space--mb_60">
                        <h6 class="section-sub-title mb-20">Testemunhos</h6>
                        <h3 class="heading">A <span class="text-color-primary">satisfação</span> dos nossos clientes.</h3>
                    </div>
                    <div class="testimonial-slider">
                        <div class="swiper-container testimonial-slider__container-two">
                            <div class="swiper-wrapper testimonial-slider__wrapper">
                              
                                 <div class="swiper-slide">
                                    <div class="testimonial-slider__single wow move-up">
                                        <h6 class="testimonial-subject">Software de Gestão - BoopGest 4.0</h6>
                                        <div class="testimonial-slider__text"><p>Com a implementação do BoopGest 4.0 na nossa empresa melhorámos a eficiência da nossa gestão, uma vez que agora conseguimos obter uma análise financeira, aprofundada, no final de cada trabalho.</p>
                                        <p>Além disso, melhorámos os nossos processos tornando a comunicação com os nossos colaboradores mais eficazes.</p>
                                        </div>
                                        <div class="author-info">
                                            <div class="testimonial-slider__media">
                                                <img src="assets/images/testimonial/lusoacier.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="testimonial-slider__author">
                                                <h6 class="name">Filipe Lopes</h6>
                                                <span class="designation">LusoAcier - Construções Metálicas</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                 <div class="swiper-slide">
                                    <div class="testimonial-slider__single wow move-up">
                                        <h6 class="testimonial-subject">Desenvolvimento de plataforma de gestão de projetos</h6>
                                        <div class="testimonial-slider__text"><p>Melhorámos, em pouco mais de 6 meses, todos os processos, mediante a implementação de uma aplicação, cujo sucesso se deve ao elevado grau de conhecimento, eficácia, rapidez e dinamismo da equipa BoopIT.</p>
                                        </div>
                                        <div class="author-info">
                                            <div class="testimonial-slider__media">
                                                <img src="assets/images/testimonial/pulso.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="testimonial-slider__author">
                                                <h6 class="name">Nuno Brito</h6>
                                                <span class="designation">PULSO Publicidade</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="testimonial-slider__single wow move-up">
                                        <h6 class="testimonial-subject">Desenvolvimento de Website</h6>
                                        <div class="testimonial-slider__text"><p>O desenvolvimento do site da Inovarcus, Lda decorreu num processo de fácil cooperação e compreensão das necessidades da empresa até à materialização do mesmo.</p>
                                        </div>
                                        <div class="author-info">
                                            <div class="testimonial-slider__media">
                                                <img src="assets/images/testimonial/inovarcus.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="testimonial-slider__author">
                                                <h6 class="name">Pedro Dias</h6>
                                                <span class="designation">InovArcus - Engenharia e Arquitetura</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="testimonial-slider__single wow move-up">
                                        <h6 class="testimonial-subject">Software de Gestão - BoopGest 4.0</h6>
                                        <div class="testimonial-slider__text"><p>Com a implementação da plataforma BoopGest 4.0 na Shoperfil, conseguimos centralizar a informação necessária ao bom funcionamento, ganhando assim tempo, organização e fluidez nos nossos processos.</p>
                                            <p>Além disso, e como os custos são a alma do negócio, com esta ferramenta conseguimos ter um controlo mais preciso e automatizado dos custos por cada trabalho realizado. </p>
                                        </div>
                                        <div class="author-info">
                                            <div class="testimonial-slider__media">
                                                <img src="assets/images/testimonial/shoperfil.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="testimonial-slider__author">
                                                <h6 class="name">Sérgio Dias</h6>
                                                <span class="designation">Shoperfil Alumínios</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        <div class="swiper-pagination swiper-pagination-t0 section-space--mt_30"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>









    
    
    

    <div class="brand-logo-slider-area section-space--ptb_60">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- brand logo slider -->
                    <div class="brand-logo-slider__container-area">
                        <div class="swiper-container brand-logo-slider__container">
                            <div class="swiper-wrapper brand-logo-slider__one">
                                <div class="swiper-slide brand-logo brand-logo--slider">
                                    <a href="#">
                                        <div class="brand-logo__image">
                                            <img src="assets/images/brand/lusoacier.png" class="img-fluid" alt="">
                                        </div>
                                        <div class="brand-logo__image-hover">
                                            <img src="assets/images/brand/lusoacier.png" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide brand-logo brand-logo--slider">
                                    <a href="#">
                                        <div class="brand-logo__image">
                                            <img src="assets/images/brand/pulso.png" class="img-fluid" alt="">
                                        </div>
                                        <div class="brand-logo__image-hover">
                                            <img src="assets/images/brand/pulso.png" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide brand-logo brand-logo--slider">
                                    <a href="#">
                                        <div class="brand-logo__image">
                                            <img src="assets/images/brand/inovarcus.png" class="img-fluid" alt="">
                                        </div>
                                        <div class="brand-logo__image-hover">
                                            <img src="assets/images/brand/inovarcus.png" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide brand-logo brand-logo--slider">
                                    <a href="#">
                                        <div class="brand-logo__image">
                                            <img src="assets/images/brand/shoperfil.png" class="img-fluid" alt="">
                                        </div>
                                        <div class="brand-logo__image-hover">
                                            <img src="assets/images/brand/shoperfil.png" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide brand-logo brand-logo--slider">
                                    <a href="#">
                                        <div class="brand-logo__image">
                                            <img src="assets/images/brand/restauranteacave.png" class="img-fluid" alt="">
                                        </div>
                                        <div class="brand-logo__image-hover">
                                            <img src="assets/images/brand/restauranteacave.png" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide brand-logo brand-logo--slider">
                                    <a href="#">
                                        <div class="brand-logo__image">
                                            <img src="assets/images/brand/portelabike.png" class="img-fluid" alt="">
                                        </div>
                                        <div class="brand-logo__image-hover">
                                            <img src="assets/images/brand/portelabike.png" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====================  End of brand logo slider area  ====================-->
</div>
<app-footer></app-footer>
</div>