<div id="footer" class="footer-area-wrapper reveal-footer bg-gray">
    <div class="footer-area section-space--ptb_80">
        <div class="container">
            <div class="row footer-widget-wrapper">
                <div class="col-lg-4 col-md-6 col-sm-6 footer-widget">
                    <div class="footer-widget__logo mb-30">
                        <img src="assets/images/logo/dark-logo-160x48.png" class="img-fluid" alt="">
                    </div>
                    <ul class="footer-widget__list">
                        <li>Rua Dr. Francisco Sá Carneiro</li> 
                        <li>Mercado Municipal de Ourém, Piso 0, Loja 29</li>
                        <li>2490-548 Ourém</li>
                        <li><a href="mailto:geral@boopit.pt" class="hover-style-link">geral@boopit.pt</a></li>
                        <li><a href="tel:+351914175981" class="hover-style-link text-black font-weight--bold">+351 914 175 981</a><p style="font-size: 9pt; font-weight:bold;">(Custo chamada rede móvel nacional)</p></li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 footer-widget">
                    <h6 class="footer-widget__title mb-20">Serviços</h6>
                    <ul class="footer-widget__list">
                        <li><a href="/software" class="hover-style-link">Software à medida</a></li>
                        <li><a href="/desenvolvimentoweb" class="hover-style-link">Desenvolvimento Web</a></li>
                        <li><a href="/portais" class="hover-style-link">Portais Corporativos</a></li>
                        <li><a href="/alojamentoweb" class="hover-style-link">Alojamento e Domínios</a></li>
                        <li><a href="/consultoria" class="hover-style-link">Consultoria</a></li>
                    </ul>
                </div>


                <div class="col-lg-2 col-md-4 col-sm-6 footer-widget">
                    <h6 class="footer-widget__title mb-20">Links Uteis</h6>
                    <ul class="footer-widget__list">
                        <li><a href="/politica-privacidade" class="hover-style-link">Termos e Condições</a></li>
                        <li><a href="/politica-privacidade" class="hover-style-link">Política de Privacidade</a></li>
                    <!--    <li><a href="#" class="hover-style-link">Política de Proteção de Dados</a></li> -->
                        <li><a href="https://www.livroreclamacoes.pt/inicio" target="_blank" class="hover-style-link">Livro de Reclamações</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-copyright-area section-space--pb_30">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 text-center text-md-left">
                    <span class="copyright-text">&copy; 2020 BoopIT - Engenharia de Software. Todos os direitos reservados.</span>
                </div>
                <div class="col-md-6 text-center text-md-right">
                    <ul class="list ht-social-networks solid-rounded-icon">


                        <li class="item">
                            <a href="https://www.facebook.com/boopitsoftware" target="_blank" aria-label="Facebook" class="social-link hint--bounce hint--top hint--primary">
                                <i class="fab fa-facebook-f link-icon"></i>
                            </a>
                        </li>
                        <li class="item">
                            <a href="http://instagram.com/boopitsoftware" target="_blank" aria-label="Instagram" class="social-link hint--bounce hint--top hint--primary">
                                <i class="fab fa-instagram link-icon"></i>
                            </a>
                        </li>
                        <li class="item">
                            <a href="https://www.linkedin.com/company/boopitsoftware" target="_blank" aria-label="Linkedin" class="social-link hint--bounce hint--top hint--primary">
                                <i class="fab fa-linkedin link-icon"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>