<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Sobre Nós</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                    <h5 class="heading">        Trabalhamos consigo para servir o seu negócio.</h5>
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-5">
                    <div class="modern-number-01">
                        <div class="image">
                            <img class="img-fluid" src="assets/images/empresa/banner.jpg" alt="">
                        </div>
                        
                      
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            Numa altura em que a <span class="text-color-primary">Transformação Digital</span> é tema de conversa, em qualquer corredor de empresas que se queira manter competitiva no mercado, surge a <span class="text-color-primary">BoopIT - Engenharia de Software</span>.
                        </div>
                        <div class="text">
                           Somos uma empresa que tem como principal objetivo ajudar a sua empresa na sua evolução tecnológica. Com esta evolução os processos internos da sua empresa tornam-se mais rápidos, simples e eficazes permitindo a tomada de decisões mais rápida. 
                        </div>
                     
                        
                    </div>
                </div>


            </div>
        
            <div class="row">
               

                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text" style="margin-top: 20px;">
                           Temos uma equipa multidisciplinar, com grande experiência em engenharia de sistemas de informação, com a capacidade de compreender as verdadeiras necessidades do nosso cliente e desenhar a solução tecnológica adequada.
                        </div>
                        <div class="text">
                           Todo o software desenvolvido é baseado nos requisitos do cliente, utilizando as mais recentes tecnologias existentes no mercado e utilizando uma metodologia ágil, tornando assim o produto final mais fiável.
                         </div>
                    </div>
                </div>


            </div>
        </div>


        
    </div>
</div>

</div>
<app-footer></app-footer>
</div>