import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desenvolvimentoweb',
  templateUrl: './desenvolvimentoweb.component.html',
  styleUrls: ['./desenvolvimentoweb.component.css']
})
export class DesenvolvimentowebComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
