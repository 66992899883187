import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ContatosComponent } from './components/contatos/contatos.component';
import { AlojamentowebComponent } from './components/alojamentoweb/alojamentoweb.component';
import { DesenvolvimentowebComponent } from './components/desenvolvimentoweb/desenvolvimentoweb.component';
import { SoftwareComponent } from './components/software/software.component';
import { Boopgest4Component } from './components/boopgest4/boopgest4.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { PortaisComponent } from './components/portais/portais.component';
import { ConsultoriaComponent } from './components/consultoria/consultoria.component';
import { PoliticaPrivacidadeComponent } from './components/politica-privacidade/politica-privacidade.component';
import { CarroComponent } from './components/carro/carro.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContatosComponent,
    AlojamentowebComponent,
    DesenvolvimentowebComponent,
    SoftwareComponent,
    Boopgest4Component,
    EmpresaComponent,
    PortaisComponent,
    ConsultoriaComponent,
    PoliticaPrivacidadeComponent,
    CarroComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
