
<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Contatos</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <div class="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
            <div class="container">
                <div class="row align-items-center">
                 

                    <div class="col-lg-12 col-lg-12">
                        <div class="contact-form-wrap">

                          
                               <form id="contact-form" action="assets/php/mail.php" method="post"> 
                                <div class="contact-form">
                                    <div class="contact-input">
                                        <div class="col-lg-4 contact-inner">
                                            <input name="con_name" type="text" placeholder="Nome *">
                                        </div>
                                        <div class="col-lg-4 contact-inner">
                                            <input name="con_email" type="email" placeholder="Email *">
                                        </div>
                                        <div class="col-lg-4 contact-inner">
                                            <input name="con_phone" type="text" placeholder="Telefone *">
                                        </div>
                                    </div>
                                    <div class="contact-inner">
                                        <input name="con_subject" type="text" placeholder="Assunto *">
                                    </div>
                                    <div class="contact-inner contact-message">
                                        <textarea name="con_message" placeholder="Mensagem"></textarea>
                                    </div>
                                    <div class="submit-btn mt-20">
                                        <button class="ht-btn ht-btn-md" onclick="window.location.href = 'https://www.boopit.pt';" type="submit">Enviar Mensagem</button>
                                        <p class="form-messege"></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-us-area infotechno-contact-us-bg section-space--pt_100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="image">
                            <img class="img-fluid" src="assets/images/banners/home-cybersecurity-contact-bg-image.png" alt="">
                        </div>
                    </div>

                    <div class="col-lg-4 ml-auto">
                        <div class="contact-info style-two text-left">

                

                            <div class="contact-list-item">
                                <a href="" class="single-contact-list">
                                    <div class="content-wrap">
                                        <div class="content">
                                            <div class="icon">
                                                <span class="fal fa-map"></span>
                                            </div>
                                            <div class="main-content">
                                                <div class="text">
                                                    <p style="font-size: 10pt;">Rua Dr. Francisco Sá Carneiro</p>
                                                    <p style="font-size: 10pt;">Mercado Municipal Ourém, Piso 0 - Loja 29</p>
                                                    <p style="font-size: 10pt;">2490-548 Ourém</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="tel:+351914175981" class="single-contact-list">
                                    <div class="content-wrap">
                                        <div class="content">
                                            <div class="icon">
                                                <span class="fal fa-phone"></span>
                                            </div>
                                            <div class="main-content" style="padding-top: 15px;">
                                                <div class="text">+351 914 175 981</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="mailto:hello@boopit.pt" class="single-contact-list">
                                    <div class="content-wrap">
                                        <div class="content">
                                            <div class="icon">
                                                <span class="fal fa-envelope"></span>
                                            </div>
                                            <div class="main-content" style="padding-top: 15px;">
                                                <div class="text">hello@boopit.pt</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-wrap-map section-space--pt_100">

            <div class="google-map">
                <div id="googleMap-3" class="embed-responsive-item googleMap-3" data-lat="39.6540652" data-Long="-8.5731886"></div>
            </div>
        </div>
        </div>
    <app-footer></app-footer>

</div>