<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Consultoria</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                    <h5 class="heading">Somos o <span class="text-color-primary">Departamento de Informática</span> que a sua empresa necessita!</h5>
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-5">
                    <div class="modern-number-01">
                        <div class="image">
                            <img class="img-fluid" src="assets/images/banners/consultoria.jpg" alt="">
                        </div>
                        
                      
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            Estamos, lado a lado, consigo no dia a dia da sua empresa para o ajudar em qualquer situação relacionada com a nossa área de especialidade.
                        </div>
                        <div class="text">
                            Temos uma equipa multidisciplinar com grande experiência em engenharia de sistemas de informação, com a capacidade de compreender as verdadeiras necessidades do nosso cliente e desenhar a solução tecnológica adequada para cada empresa.
                        </div>
                            <div class="text">
                            Analisamos, aconselhamos, desenvolvemos e acompanhamos todo o projeto ou processo que a sua empresa necessita. Qualquer dúvida que tenha, nós procuramos a resposta ideal para a sua questão.
                        </div>
                     
                        
                    </div>
                </div>


            </div>
        
        </div>


        
    </div>
</div>

</div>
<app-footer></app-footer>
</div>