import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { CarroComponent } from './components/carro/carro.component';
import { ContatosComponent } from './components/contatos/contatos.component';
import { AlojamentowebComponent } from './components/alojamentoweb/alojamentoweb.component';
import { DesenvolvimentowebComponent } from './components/desenvolvimentoweb/desenvolvimentoweb.component';
import { SoftwareComponent } from './components/software/software.component';
import { Boopgest4Component } from './components/boopgest4/boopgest4.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { PortaisComponent } from './components/portais/portais.component';
import { ConsultoriaComponent } from './components/consultoria/consultoria.component';
import { PoliticaPrivacidadeComponent } from './components/politica-privacidade/politica-privacidade.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {path: 'home', component: HomeComponent},
  {path: 'carro', component: CarroComponent},
  {path: 'contatos', component: ContatosComponent},
  {path: 'alojamentoweb', component: AlojamentowebComponent},
  {path: 'desenvolvimentoweb', component: DesenvolvimentowebComponent},
  {path: 'software', component: SoftwareComponent},
{path: 'boopgest', component: Boopgest4Component},
{path: 'empresa', component: EmpresaComponent},
{path: 'portais', component: PortaisComponent},
{path: 'consultoria', component: ConsultoriaComponent},
{path: 'politica-privacidade', component: PoliticaPrivacidadeComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
