

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                    <h5 class="heading">Cruzou-se com um dos nossos  <span class="text-color-primary">veículos </span> ?</h5>
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-12">
                    <div class="modern-number-01">
                        <div class="image">
                            <img class="img-fluid" width="800px"  src="assets/images/banners/carro.jpg" alt="">
                        </div>
                        
                      
                    </div>
                </div>

                
            </div>



            <div class="row">
                <div class="col-lg-12">
                    <!-- section-title-wrap Start -->
                    <div class="section-title-wrap text-center section-space--mb_60">               
                        <h5 class="heading">Não deixe a sua  <span class="text-color-primary">empresa </span> ficar parada no tempo!!!</h5>
                    </div>
                    <!-- section-title-wrap Start -->
                </div>
            </div>


            <div class="row">
                <div class="col-lg-12">
                    <!-- section-title-wrap Start -->
                    <div class="section-title-wrap text-center section-space--mb_60">               
                        <h5 class="heading">Preencha  <span class="text-color-primary">o formulário abaixo, </span> nós entraremos em contato.</h5>
                    </div>
                    <!-- section-title-wrap Start -->
                </div>
            </div>


                <div class="row align-items-center">
                 

                    <div class="col-lg-12 col-lg-12">
                        <div class="contact-form-wrap">

                          
                               <form id="contact-form" action="assets/php/mail.php" method="post"> 
                                <div class="contact-form">
                                    <div class="contact-input">
                                        <div class="col-lg-4 contact-inner">
                                            <input name="con_name" type="text" placeholder="Nome *">
                                        </div>
                                        <div class="col-lg-4 contact-inner">
                                            <input name="con_email" type="email" placeholder="Email *">
                                        </div>
                                        <div class="col-lg-4 contact-inner">
                                            <input name="con_phone" type="text" placeholder="Telefone *">
                                        </div>
                                    </div>
                                    <div class="contact-inner">
                                        <input name="con_subject" type="text" placeholder="Assunto *">
                                    </div>
                                    <div class="contact-inner contact-message">
                                        <textarea name="con_message" placeholder="Mensagem"></textarea>
                                    </div>
                                    <div class="submit-btn mt-20">
                                        <button class="ht-btn ht-btn-md" onclick="window.location.href = 'https://www.boopit.pt';" type="submit">Enviar Mensagem</button>
                                        <p class="form-messege"></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        
        </div>


        
    </div>
</div>

</div>
<app-footer></app-footer>
</div>