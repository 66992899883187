
 <div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Alojamento Web</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">
    <div class="pricing-table-area section-space--ptb_100 ">
        <div class="pricing-table-title-area position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrapper text-center section-space--mb_60 wow move-up">
                            <h3 class="section-title">O plano certo para <span class="text-color-primary"> a sua empresa!</span> </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pricing-table-content-area">
            <div class="container">
                <div class="row pricing-table-two">
                    <div class="col-12 col-md-6 col-lg-6 col-xl-3 pricing-table wow move-up">
                        <div class="pricing-table__inner">
                            <div class="pricing-table__header">
                                <h5 class="pricing-table__title">Boop 10</h5>
                                <div class="pricing-table__price-wrap">
         
                                    <h6 class="price">10</h6>
                                    <h6 class="period">GB</h6>
                                </div>
                            </div>
                            <div class="pricing-table__body">
                                <ul class="pricing-table__list">
                                    <li><p style="margin-bottom: 2px;">Website Apresentação</p>
                                        <p style="font-size: xx-small;">logotipo, contatos, morada, formulário contato</p></li>
                                    <li>10 GB espaço em disco</li>
                                    <li>1 Domínio (.pt / .com / .net)</li>
                                    <li>&#8734; contas e-mail</li>
                                    <li>&#8734; tráfego</li>
                                    <li>&#8734; base de dados</li>
                                    <li>Backups diários</li>
                                </ul>
                            </div>
                            <div class="pricing-table__footer">
                                <a href="/contatos" class="ht-btn ht-btn-default btn--secondary">Sob consulta</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-3 pricing-table pricing-table--popular wow move-up">
                        <div class="pricing-table__inner">
                            <div class="pricing-table__feature-mark">
                                <span>Melhor Escolha</span>
                            </div>
                            <div class="pricing-table__header">
                                <h5 class="pricing-table__title">Boop 25</h5>
                                <div class="pricing-table__price-wrap">
         
                                    <h6 class="price">25</h6>
                                    <h6 class="period">GB</h6>
                                </div>
                            </div>
                            <div class="pricing-table__body">
                                <ul class="pricing-table__list">
                                    <li><p style="margin-bottom: 2px;">Website Apresentação</p>
                                        <p style="font-size: xx-small;">logotipo, contatos, morada, formulário contato</p></li>
                                    <li>25 GB espaço em disco</li>
                                    <li>1 Domínio (.pt / .com / .net)</li>
                                    <li>&#8734; contas e-mail</li>
                                    <li>&#8734; tráfego</li>
                                    <li>&#8734; base de dados</li>
                                    <li>Backups diários</li>
                                </ul>
                            </div>
                            <div class="pricing-table__footer">
                                <a href="/contatos" class="ht-btn ht-btn-default btn--secondary">Sob consulta</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-3 pricing-table wow move-up">
                        <div class="pricing-table__inner">
                            <div class="pricing-table__header">
                                <h5 class="pricing-table__title">Boop 50</h5>
                                <div class="pricing-table__price-wrap">
         
                                    <h6 class="price">50</h6>
                                    <h6 class="period">GB</h6>
                                </div>
                            </div>
                            <div class="pricing-table__body">
                                <ul class="pricing-table__list">
                                    <li><p style="margin-bottom: 2px;">Website Apresentação</p>
                                        <p style="font-size: xx-small;">logotipo, contatos, morada, formulário contato</p></li>
                                    <li>50 GB espaço em disco</li>
                                    <li>1 Domínio (.pt / .com / .net)</li>
                                    <li>&#8734; contas e-mail</li>
                                    <li>&#8734; tráfego</li>
                                    <li>&#8734; base de dados</li>
                                    <li>Backups diários</li>
                                </ul>
                            </div>
                            <div class="pricing-table__footer">
                                <a href="/contatos" class="ht-btn ht-btn-default btn--secondary">Sob consulta</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-3 pricing-table wow move-up">
                        <div class="pricing-table__inner">
                            <div class="pricing-table__header">
                                <h5 class="pricing-table__title">Boop 100</h5>
                                <div class="pricing-table__price-wrap">
         
                                    <h6 class="price">100</h6>
                                    <h6 class="period">GB</h6>
                                </div>
                            </div>
                            <div class="pricing-table__body">
                                <ul class="pricing-table__list">
                                    <li><p style="margin-bottom: 2px;">Website Apresentação</p>
                                        <p style="font-size: xx-small;">logotipo, contatos, morada, formulário contato</p></li>
                                    <li>100 GB espaço em disco</li>
                                    <li>1 Domínio (.pt / .com / .net)</li>
                                    <li>&#8734; contas e-mail</li>
                                    <li>&#8734; tráfego</li>
                                    <li>&#8734; base de dados</li>
                                    <li>Backups diários</li>
                                </ul>
                            </div>
                            <div class="pricing-table__footer">
                                <a href="/contatos" class="ht-btn ht-btn-default btn--secondary">Sob consulta</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>


    <!--====================  footer area ====================-->
    <app-footer></app-footer>
    <!--====================  End of footer area  ====================-->
</div>
