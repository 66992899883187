<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Portais Corporativos</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                    <h5 class="heading"> <span class="text-color-primary">Automatize</span> a comunicação com os seus funcionários!</h5>
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-5">
                    <div class="modern-number-01">
                        <div class="image">
                            <img class="img-fluid" src="assets/images/banners/portais.jpg" alt="">
                        </div>
                        
                      
                    </div>
                </div>

                <div class="col-lg-7">
             
                    <div class="cybersecurity-about-text">
                        <div class="text">       Os portais corporativos são aplicações semelhantes a websites disponiveis na Internet, com acesso restrito a funcionários ou clientes de determinada empresa, onde se encontra informações necessárias ao bom funcionamento da empresa.</div>
                        <div class="text"> Neste tipo de plataforma os colaboradores podem ter acesso a um conjunto de informações tais como, marcação de férias, registo de ausências, pedidos de formação, entre outros.</div>
                        <div class="text">
                            Este tipo de serviço, também, pode ser focado nos clientes, por exemplo, uma plataforma onde os próprios podem fazer as suas encomendas, ficando estas disponíveis em tempo real, para iniciar o processo de expedição, poupando assim tempo, recursos e dinheiro.
                         </div>
                    </div>
                </div>
      


            </div>
        
           
        </div>


        
    </div>
</div>


<div class="about-delivering-wrapper bg-gray section-space--ptb_20">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title-wrap text-center section-space--mb_20">
                    <h3 class="heading">Vantagens</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="delivering-optimal-wrap">
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Agilizar a comunicação</h6>
                           
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                           
                            <h6 class="title">Agilizar processos independentemente da localização geográfica</h6>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div class="col-lg-6">
                <div class="delivering-optimal-wrap">
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Melhorar a comunicação com o cliente</h6>
                          
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Reduzir o tempo de tomada de decisão</h6>
                           
                        </div>
                    </div>
                    
                <div class="list-item">
                    <div class="marker"></div>
                    <div class="title-wrap">
                        <h6 class="title">Informação centralizada</h6>
                      
                    </div>
                </div>
                </div>
           
            </div>
        </div>
    </div>
</div>

</div>
<app-footer></app-footer>
</div>