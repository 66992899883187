<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Política de Privavidade</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                 
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            Este documento visa informar que a BoopIT - Engenharia de Software Unipessoal, Lda, doravante designada por "BoopIT", registada sob o número 516 207 202, respeita a privacidade dos visitantes do seu Website e empreende todos os cuidados para proteger as suas informações, dando-lhe aqui uma perspetiva geral da forma como tratamos os dados, de acordo com o disposto no Regulamento (EU) 2016/679 do Parlamento Europeu e do Conselho – Regulamento Geral sobre a Proteção de Dados (RGPD). Esta política de privacidade, consiste então em prestar esclarecimentos acerca das informações pessoais que recolhemos e da forma como as podemos utilizar, bem como esclarecer quais as normas de segurança adotadas para proteger a identidade e os dados pessoais dos visitantes, no que respeita à utilização do nosso website. A grande generalidade dos dados recolhidos, serão processados e tratados de acordo com os serviços solicitados e previamente acordados entre o cliente e a nossa empresa.

                            Ao aceder ou continuar no nosso website, está a concordar com a política aqui disposta.
                            
                            Poderá conhecer melhor todos os regulamentos das novas leis de proteção de dados em: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/eu-data-protection-rules_en" target="_blank">Novo Regulamento de Proteção de Dados</a>
                        </div>
                     
                        
                    </div>
                </div>


            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title-wrapper text-center section-space--mb_60 wow move-up">
                        <h3 class="section-title">Que tipo de informações recolhemos:</h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            Podem ser solicitadas informações pessoais, como o nome, o contacto telefónico e o endereço de correio eletrónico do visitante, quando este preenche o formulário de contacto quer como trabalhador, candidato a emprego, formador, cliente, fornecedor, visitante, etc. e/ou quando envia mensagens de correio eletrónico para os nossos serviços.
                        
                     
                    </div>     
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Utilização das informações dos visitantes: </b>  </div> 
                            <div class="text">   Todas as informações pessoais que obtemos serão registadas, utilizadas e protegidas em conformidade com legislação comunitária vigente em matéria de proteção de dados e com esta política de privacidade. Os dados recolhidos têm como finalidade o envio de informações por correio eletrónico acerca dos nossos produtos/serviços ou a resposta a uma solicitação do visitante, como por exemplo para um pedido de orçamento. O envio das informações apenas ocorre, quando o titular dos dados tiver expressamente solicitado a receção de mensagens de correio eletrónico da parte da BoopIT.  
                
                            </div>
                           
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Proteção das informações dos visitantes: </b>  </div> 
                            <div class="text">  As informações que os visitantes fornecem através do nosso Website, estão protegidas no âmbito da implementação das várias medidas de segurança, nomeadamente através de acesso condicionado e de salvaguardada confidencialidade das informações. Contudo, a BoopIT alerta o visitante que, ao fornecer informações pessoais online, existe um risco de terceiros poderem intercetar e utilizar estas informações, pelo que em termos de segurança de qualquer dado que divulgue online, fá-lo-á por sua própria conta e risco.
                            </div>
                           
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Privacidade de Menores: </b>  </div> 
                            <div class="text">  A BoopIT está empenhada em proteger a privacidade de crianças e jovens, pelo que não aceita dados pessoais de menores, com idade inferior a 16 anos. Qualquer informação registada por criança ou jovem com idade inferior à mencionada, será imediatamente destruída.   </div>
                           
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Nosso Contato: </b>  </div> 
                            <div class="text">  
                                Se o visitante tiver quaisquer perguntas ou comentários relativamente ao nosso Website e/ou à nossa política de privacidade, deverá comunicá-lo por escrito para o endereço postal ou informaticamente para o endereço eletrónico presentes no nosso website.
                                 </div>
                           
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Consentimento do Titular dos Dados: </b>  </div> 
                            <div class="text">  
                                Ao utilizar o nosso Website confirma que aceita os termos desta Política de Privacidade, assim como os nossos Termos e Condições. Caso não concorde com os termos da nossa Política de Privacidade e os nossos Termos e Condições, solicita-se que não nos forneça informações pessoais e cesse a utilização no nosso Website.
                                 </div>
                           
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Direito do Titular dos Dados: </b>  </div> 
                            <div class="text">  
                                O titular dos dados tem o direito de receber uma cópia das informações pessoais em poder da BoopIT, bastando para isso solicitar através dos meios convencionais e já acima descritos.
                              </div>
                           
                    </div>
                </div>
            </div>


            
         <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Política de Privacidade: </b>  </div> 
                            <div class="text">  
                                A política de privacidade online aplica-se apenas às informações recolhidas através do nosso Website e não às informações recolhidas offline. Estas estão sujeitas a outros princípios legais e/ou regulamentares que poderá consultar através do questionamento à nossa empresa.
                              </div>
                           
                    </div>
                </div>
            </div>

               <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">
                            <b>- Resolução Alternativa de Litígios de Consumo (RAL): </b>  </div> 
                            <div class="text">  
                                Entidade RALC (art.18.º da lei n.º 144/2015) Em caso de litígio o consumidor pode recorrer a uma Entidade de Resolução Alternativa de Litígios de Consumo (RALC): CNIACC – Centro Nacional de Informação e Arbitragem de Conflitos de Consumo Morada: Faculdade de Direito da Universidade Nova de Lisboa, Campus de Campolide, 1099-032 Lisboa Telefone: 21 384 74 84 E-mail: cniacc@fd.unl.pt Site: www.arbitragemdeconsumo.org/ Facebook: www.facebook.com/cniacc Informações em Portal do Consumidor: www.consumidor.pt
                              </div>
                           
                    </div>
                </div>
            </div>

            


            <div class="row">
                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                     
                            <div class="text">  
                                <p><a href="mailto:geral@boopit.pt">geral@boopit.pt</a> </p>
                                <p> Dezembro 2020</p>
                              </div>
                           
                    </div>
                </div>
            </div>








      

 
        </div>


        
    </div>
</div>

</div>
<app-footer></app-footer>
</div>