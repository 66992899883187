 <!-- breadcrumb-area start -->
 <div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Desenvolvimento Web</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb-area end -->


<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <!--===========  Projects wrapper Start =============-->
        <div class="projects-wrapper section-space--pb_70 section-space--pt_100 projects-masonary-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrap text-center section-space--mb_40">
                            <h3 class="heading">Os nossos<span class="text-color-primary"> projetos</span></h3>
                        </div>

                        <div class="messonry-button text-center  section-space--mb_60">
                            <button data-filter="*" class="is-checked"><span class="filter-text">Todos</span><span class="filter-counter">28</span></button>
                            <button data-filter=".cat--1"><span class="filter-text">Website</span> <span class="filter-counter">26</span></button>
                            <button data-filter=".cat--2"><span class="filter-text">Loja on-line</span> <span class="filter-counter">2</span></button>                        
                        </div>
                    </div>
                </div>

                <div class="projects-case-wrap">
                    <div class="row mesonry-list">

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://templariosbtt.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" alt="Templários BTT - Clube Desportivo Templários BTT e Aventura" src="assets/images/desenvolvimentoweb/templarios.png" >
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Templários BTT - Clube Desportivo Templários BTT e Aventura

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.indumatec.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" alt="Indumatec - Comércio de Equipamentos Industriais" src="assets/images/desenvolvimentoweb/indumatec.png">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Indumatec - Comércio de Equipamentos Industriais

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.reviextra.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" alt="Reviextra" src="assets/images/desenvolvimentoweb/reviextra.png" >
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Reviextra

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://marcolopesautomoveis.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" alt="Marco Lopes Automóveis" src="assets/images/desenvolvimentoweb/marcolopes.png" >
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Marco Lopes Automóveis

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="https://seiva.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" alt="Seiva - Mobiliário original de alta qualidade" src="assets/images/desenvolvimentoweb/seiva.png" >
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Loja Online</div>
                                        <h6 class="heading">Seiva - Mobiliário original de alta qualidade

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://jnconsulting.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" alt="JN Consulting Real Estate" src="assets/images/desenvolvimentoweb/jnconsulting.png" >
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">JN Consulting Real Estate

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="https://spreadzero.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/spreadzero.png" alt="SpreadZero – O livro do crédito à habitação">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Loja Online</div>
                                        <h6 class="heading">SpreadZero – O livro do crédito à habitação

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://reboureense.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/reboureense.png" alt="Rebobinadora Oureense">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Rebobinadora Oureense

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>




                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.10dejulho.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/10dejulho.png" alt="10 de Julho Training Academy">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">10 de Julho Training Academy

                                        </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>




                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.metodoobliquo.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/metodoobliquo.png" alt="Método Oblíquo - Construções e Remodelações">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Método Oblíquo - Construções e Remodelações</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.nvfpinturas.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/nvf.png" alt="NVF - Pinturas e Pavimentos em Madeira">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">NVF - Pinturas e Pavimentos em Madeira</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.freguesiadepelma.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/pelma.png" alt="Junta Freguesia de Pelmá">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Junta Freguesia de Pelmá</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.enfipro.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/enfipro.png" alt="ENFIPRO - Engenharia, Fiscalização e Gestão De Projetos">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">ENFIPRO - Engenharia, Fiscalização e Gestão De Projetos</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.coviclass.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/coviclass.png" alt="Coviclass - Comércio de Automóveis">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Coviclass - Comércio de Automóveis</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.wagnermadeiras.pt" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/wagner.png" alt="Wagner Madeiras - Comércio de Madeiras e Derivados">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Wagner Madeiras - Comércio de Madeiras e Derivados</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>




                


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.sportchip.net/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/sportchip.png" alt="SPORTCHIP - Cronometragem de Eventos">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">SPORTCHIP - Cronometragem de Eventos</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>
                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.lagrostone.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/lagrostone.png" alt="Lagrostone - Mármores e Granitos">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Lagrostone - Mármores e Granitos</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.sbrojetos.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/sbprojetos.png" alt="SB Projetos - Arquitetura e Engenharia">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">SB Projetos - Arquitetura e Engenharia</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>



                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.portaourem.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/portaourem.png" alt="PortaOurém - Portas Secionadas e Automatismos">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">PortaOurém - Portas Secionadas e Automatismos</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>





                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.alburitelense.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/alburitelense.png" alt="Auto Agrícola Alburitelense">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Auto Agrícola Alburitelense</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.ecolux.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/ecolux.png" alt="Ecolux - A Tinta com Vida">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Ecolux - A Tinta com Vida</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.placpaint.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/placpaint.PNG" alt="Placpaint - Materiais de Construção">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Placpaint - Materiais de Construção</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.rubramac.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/rubramac.PNG" alt="Rubramac - Construção Civil e Obras Públicas">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Rubramac - Construção Civil e Obras Públicas</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="http://www.caractervirtual.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/cvirtual.png" alt="CaracterVirtual - Construções Metálicas">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">CaracterVirtual - Construções Metálicas</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="http://www.confortermic.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/confortermic.png" alt="Confortermic - Caixilharia em Alumínio">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Confortermic - Caixilharia em Alumínio</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>


                        <!--<div class="resizer"></div>-->
                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="http://www.inovarcus.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/inovarcus.png" alt="Inovarcus - Arquitetura e Engenharia">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Inovarcus - Arquitetura e Engenharia</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://www.portelabike.com/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/portelabike.png" alt="PortelaBike - Loja de Bicicletas">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">PortelaBike - Loja de Bicicletas</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="https://restauranteacave.pt/" target="_blank" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image">
                                        <img class="img-fluid" src="assets/images/desenvolvimentoweb/restauranteacave.png" alt="Restaurante A Cave - Fátima">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Website</div>
                                        <h6 class="heading">Restaurante A Cave - Fátima</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        
                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                           <p></p>
                            <!-- Projects Wrap End -->
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!--====================  footer area ====================-->
    <app-footer></app-footer>
    <!--====================  End of footer area  ====================-->
</div>