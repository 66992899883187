<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h1 class="breadcrumb-title">Desenvolvimento de Software à Medida</h1>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">

<div class="feature-large-images-wrapper section-space--ptb_100">
    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <!-- section-title-wrap Start -->
                <div class="section-title-wrap text-center section-space--mb_60">               
                    <h5 class="heading">Soluções desenhadas à medida de cada negócio, acarreta um conjunto de <span class="text-color-primary">benefícios</span>, <br /> permitindo adaptar as ferramentas de trabalho à realidade da sua  <span class="text-color-primary">empresa</span>.</h5>
                </div>
                <!-- section-title-wrap Start -->
            </div>
        </div>

        <div class="cybersecurity-about-box section-space--pb_100">
            <div class="row">
                <div class="col-lg-5">
                    <div class="modern-number-01">
                        <div class="image">
                            <img class="img-fluid" src="assets/images/banners/software-engineering.jpg" alt="">
                        </div>
                        
                      
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="cybersecurity-about-text">
                        <div class="text">No dia a dia, as empresas deparam-se com processos manuais que são passíveis de automatização, recorrendo a um software desenhado à medida da necessidade da mesma, poupando assim tempo e recursos que poderão ser alocados a outras funções mais importantes no seu negócio. A este processo chamamos <span class="text-color-primary">Transformação Digital</span>.</div>
                        
                    </div>
                </div>


            </div>
        
            <div class="row">
               

                <div class="col-lg-12">
                    <div class="cybersecurity-about-text">
                        <div class="text">A <span class="text-color-primary">BoopIT - Engenharia de Software</span> tem uma equipa de especialistas capacitada para, em conjunto consigo, analisar todos os processos da sua empresa para desenhar e desenvolver a automatização dos mesmos com a maior transparência e eficiência para ambas as partes.</div>
                        
                    </div>
                </div>


            </div>
        </div>


        
    </div>
</div>


<div class="about-delivering-wrapper bg-gray section-space--ptb_20">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title-wrap text-center section-space--mb_20">
                    <h3 class="heading">Vantagens</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="delivering-optimal-wrap">
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Sistema de acordo com as necessidades da organização</h6>
                           
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                           
                            <h6 class="title">Desenvolve novas funcionalidades</h6>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Melhora a produtividade</h6>
                           
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Vantagem competitiva</h6>
                          
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="delivering-optimal-wrap">
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Promove uma informação centralizada</h6>
                          
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Automatiza todos os processos</h6>
                          
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="marker"></div>
                        <div class="title-wrap">
                            <h6 class="title">Acessível em qualquer dispositivo (desktop, tablet ou smartphone)</h6>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-slider-area section-space--ptb_60">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <!-- brand logo slider -->
                <div class="brand-logo-slider__container-area">
                    <div class="swiper-container brand-logo-slider__container">
                        <div class="swiper-wrapper brand-logo-slider__one">
                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/netcore.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/tech/netcore.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/csharp.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/tech/csharp.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/php.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/brand/php.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/reactjs.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/brand/reactjs.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/angular.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/tech/angular.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/mysql.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/tech/mysql.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>

                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/sqlserver.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/tech/sqlserver.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>

                            <div class="swiper-slide brand-logo brand-logo--slider">
                                <a href="#">
                                    <div class="brand-logo__image">
                                        <img src="assets/images/tech/html5.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="brand-logo__image-hover">
                                        <img src="assets/images/tech/html5.png" class="img-fluid" alt="">
                                    </div>
                                </a>
                            </div>
                          
                           
                        
                       
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<app-footer></app-footer>
</div>