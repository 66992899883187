import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alojamentoweb',
  templateUrl: './alojamentoweb.component.html',
  styleUrls: ['./alojamentoweb.component.css']
})
export class AlojamentowebComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
