<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<!-- Toolbar -->
<div class="page">
<app-header></app-header>
<router-outlet></router-outlet>


  <a href="#" class="scroll-top" id="scroll-top">
    <i class="arrow-top fal fa-long-arrow-up"></i>
    <i class="arrow-bottom fal fa-long-arrow-up"></i>
</a>
<!--====================  End of scroll top  ====================-->
<!-- Start Toolbar -->

<!-- End Toolbar -->

<!--====================  mobile menu overlay ====================-->
<div class="mobile-menu-overlay" id="mobile-menu-overlay">
    <div class="mobile-menu-overlay__inner">
        <div class="mobile-menu-overlay__header">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-6 col-8">
                        <!-- logo -->
                        <div class="logo">
                            <a href="/home">
                                <img src="assets/images/logo/logo-dark.png" class="img-fluid" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-4">
                        <!-- mobile menu content -->
                        <div class="mobile-menu-content text-right">
                            <span class="mobile-navigation-close-icon" id="mobile-menu-close-trigger"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-menu-overlay__body">
            <nav class="offcanvas-navigation">
                <ul>
                    <li class="has-children">
                        <a href="/home">Home</a>                 
                    </li>
                    <li class="has-children">
                        <a href="/empresa">A Empresa</a>                 
                    </li>
                    <li class="has-children">
                        <a href="#">Soluções</a>
                        <ul class="sub-menu">
                            <li><a href="/software"><span>Software à medida</span></a></li>
                            <li><a href="/desenvolvimentoweb"><span>Desenvolvimento Web</span></a></li>
                            <li><a href="/portais"><span>Portais Corporativos</span></a></li>
                            <li><a href="/alojamentoweb"><span>Alojamento e Domínios</span></a></li>
                            <li><a href="/consultoria"><span>Consultoria</span></a></li>
                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="#">Produtos</a>
                        <ul class="sub-menu">
                            <li><a href="/boopgest"><span>BoopGest 4.0</span></a></li>
                            
                        </ul>
                    </li>
                   <!-- <li class="has-children">
                        <a href="/home">Clientes</a>                 
                    </li>-->
                    <li class="has-children">
                        <a href="/contatos">Contatos</a>                 
                    </li>
                
                </ul>
            </nav>
        </div>
    </div>
</div>

</div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<router-outlet></router-outlet>