import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boopgest4',
  templateUrl: './boopgest4.component.html',
  styleUrls: ['./boopgest4.component.css']
})
export class Boopgest4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
