<div class="header-area">

    <div class="header-top-bar-info bg-gray d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="top-bar-wrap">
                        <div class="top-bar-left">
                            <div class="top-bar-text"></div>
                        </div>
                        <div class="top-bar-right">
                            <ul class="top-bar-info">
                                <!--<li class="info-item">
                                    <a href="tel:+351914175981" class="info-link">
                                        <i class="info-icon fa fa-phone"></i>
                                        <span class="info-text"><strong>+351 914 175 981 </strong></span>
                                    </a>
                                </li> -->
                                <li class="info-item">

                                    <a href="mailto:geral@boopit.pt" class="info-link">
                                        <i class="info-icon fa fa-envelope"></i>
                                    <span class="info-text"><strong>geral@boopit.pt</strong></span>
                                    </a>

                                 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="header-bottom-wrap header-sticky bg-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="header position-relative">
                        <!-- brand logo -->
                        <div class="header__logo">
                            <a href="/home">
                                <img src="assets/images/logo/logo.png" class="img-fluid" alt="">
                            </a>
                        </div>

                        <div class="header-right">

                            <!-- navigation menu -->
                            <div class="header__navigation menu-style-three d-none d-xl-block">
                                <nav class="navigation-menu">
                                    <ul>
                                        <li>
                                            <a href="/home" ><span>Home</span></a>
                                            
                                        </li>
                                        <li>
                                            <a href="/empresa"><span>A Empresa</span></a>
                                           
                                        </li>
                                        <li class="has-children has-children--multilevel-submenu">
                                            <a href="" onclick="return false;"><span>Soluções</span></a>
                                            <ul class="submenu">
                                                <li><a href="/software"><span>Software à medida</span></a></li>
                                                <li><a href="/desenvolvimentoweb"><span>Desenvolvimento Web</span></a></li>
                                                <li><a href="portais"><span>Portais Corporativos</span></a></li>
                                                <li><a href="/alojamentoweb"><span>Alojamento e Domínios</span></a></li>
                                                <li><a href="/consultoria"><span>Consultoria</span></a></li>
                                            </ul>
                                        </li>
                                        <li class="has-children has-children--multilevel-submenu">
                                            <a href="" onclick="return false;"><span>Produtos</span></a>
                                            <!-- mega menu -->
                                           <ul class="submenu">
                                                <li><a href="/boopgest"><span>BoopGest 4.0</span></a></li>
                                             
                                            </ul>
                                        </li>
                                       <!-- <li>
                                            <a href=""><span>Clientes</span></a>
                                           
                                          
                                        </li>-->
                                        <li>
                                            <a href="/contatos"><span>Contatos</span></a>
                                            <!-- multilevel submenu -->
                                          
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                         

                            <!-- mobile menu -->
                            <div class="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger">
                                <i></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
